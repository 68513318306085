import React from "react";
import Button from "@material-ui/core/Button";

import "./Sidebar.styles.scss";
import { SidebarData } from "./SidebarData";

const openInNewTab = (url) => {
  if (process.env.REACT_APP_ENV === "prod") {
    url = process.env.REACT_APP_PROD;
  } else {
    url = process.env.REACT_APP_DEV;
  }
  //redirect
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const Sidebar = () => {
  return (
    <ul className="sidebarList">
      <div className="assets">ASSETS</div>
      {SidebarData.map((val, key) => {
        return (
          <li
            key={key}
            className="row"
            id={window.location.pathname === val.link ? "active" : ""}
            onClick={() => {
              window.location.pathname = val.link;
            }}
          >
            <div id="icon">{val.icon}</div>
            <div id="name">{val.name}</div>
          </li>
        );
      })}
      <div className="button">
        <Button
          variant="contained"
          onClick={() => openInNewTab()}
          style={{
            borderRadius: 10,
            backgroundColor: "#53ffb0",
            fontSize: "16px",
            width: "200px",
          }}
          disableElevation
        >
          Start Trading
        </Button>
      </div>
    </ul>
  );
};

export default Sidebar;
