import React from "react";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import "./NavBar.styles.scss";
import { SidebarData } from "../sidebar/SidebarData";

const openInNewTab = (url) => {
  if (process.env.REACT_APP_ENV === "prod") {
    url = process.env.REACT_APP_PROD;
  } else {
    url = process.env.REACT_APP_DEV;
  }
  
  //redirect
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const styles = {
  navBar: { top: AppBar.height },
};

export class NavDrawer extends React.Component {
  render() {
    return (
      <Drawer
        className="drawer"
        anchor="left"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer(false)}
        width={200}
        containerstyle={styles.navBar}
      >
        <div
          onClick={this.props.toggleDrawer(false)}
          onKeyDown={this.props.toggleDrawer(false)}
        >
          <ul className="SidebarList-mobile">
            <div className="assets-mobile">ASSETS</div>
            {SidebarData.map((val, key) => {
              return (
                <li
                  key={key}
                  className="row-mobile"
                  id={
                    window.location.pathname === val.link ? "active-mobile" : ""
                  }
                  onClick={() => {
                    window.location.pathname = val.link;
                  }}
                >
                  {""}
                  <div id="icon-mobile">{val.icon}</div>
                  {""}
                  <div id="name-mobile">{val.name}</div>
                </li>
              );
            })}
            <div className="button-mobile">
              <Button
                variant="contained"
                onClick={() => openInNewTab()}
                style={{
                  borderRadius: 10,
                  backgroundColor: "#53ffb0",
                  fontSize: "16px",
                  width: "200px",
                }}
                disableElevation
              >
                Start Trading
              </Button>
            </div>
          </ul>
        </div>
      </Drawer>
    );
  }
}