import React from "react";
import { useParams } from "react-router-dom";
import "./itb.scss";

const ItbMobile = () => {
  let tokenName = useParams();
  JSON.stringify(tokenName);


  window.itbWidgetInit({
    apiKey: "XqZS391hks6f0oYeeilWU86rZH6STtti1lWpUwnL",
    language: "en",
    options: {
      tokenId: tokenName.name,
      loader: true,
      tokenSummary: {
        showTotalExchangesInflows: true,
        showTotalExchangesOutflows: true,
      },
    },
  });

  return (
    <div className="itb-mobile">
      <div className="night-mode">
        <div className="widget-container-mobile">
          <div data-target="itb-widget" data-type="token-summary"></div>
          <div data-target="itb-widget" data-type="signals-v2"></div>
          <div className="widget-footer-mobile">
            <div data-target="itb-widget" data-type="call-to-action"></div>
            <div data-target="itb-widget" data-type="powered-by"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItbMobile;