import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isMobile } from "react-device-detect";

import "./App.css";
import Logo from "./assets/pdax_logo/PDAX-logo-invertedhires@2x.png";
import NavBar from "./components/mobile_view/NavBar";
import ItbMobile from "./components/mobile_view/itb_mobile";
import WebView from './components/homepage';






const App = () => {

  if (isMobile) {
    return (
      <div className="App">
        <div className="mobile-header">
          <NavBar />
          <Router>
            <Switch>
              <Route exact path="/" component={() => <Redirect to="/BTC" />} />
              <Route path="/:name" component={ItbMobile} />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
  return (
    
    <div className="App">
      <img src={Logo} className="App-logo" alt="logo" />
      <div className="App-header">
    <WebView />
      </div>
    </div>
  );
};

export default App;