import React from "react";

import { ReactComponent as BTCIcon } from "../../assets/crypto_icon/icon_btc.svg";
import { ReactComponent as BCHIcon } from "../../assets/crypto_icon/icon_bch.svg";
import { ReactComponent as LTCIcon } from "../../assets/crypto_icon/icon_ltc.svg";
import { ReactComponent as USDTIcon } from "../../assets/crypto_icon/icon_usdt.svg";
import { ReactComponent as USDCIcon } from "../../assets/crypto_icon/icon_usdc.svg";
import { ReactComponent as ETHIcon } from "../../assets/crypto_icon/icon_eth.svg";
import { ReactComponent as LINKIcon } from "../../assets/crypto_icon/icon_link.svg";
import { ReactComponent as AAVEICon } from "../../assets/crypto_icon/icon_aave.svg";
import { ReactComponent as COMPIcon } from "../../assets/crypto_icon/icon_comp.svg";
import { ReactComponent as GRTIcon } from "../../assets/crypto_icon/icon_grt.svg";
import { ReactComponent as ENJIcon } from "../../assets/crypto_icon/icon_enj.svg";
import { ReactComponent as BATIcon } from "../../assets/crypto_icon/icon_bat.svg";

import { ReactComponent as UNIIcon } from "../../assets/crypto_icon/icon_uni.svg";

export const SidebarData = [
  {
    name: "BTC",
    icon: <BTCIcon />,
    link: "/",
   
  },
  {
    name: "ETH",
   icon: <ETHIcon />,
    link: "/ETH",
    
  },
  {
    name: "USDT",
   icon: <USDTIcon />,
    link: "/USDT",
   
  },
  {
    name: "USDC",
   icon: <USDCIcon />,
    link: "/usdc",
    
  },
  {
    name: "BCH",
    icon: <BCHIcon />,
    link: "/BCH",
   
  },
  {
    name: "LTC",
    icon: <LTCIcon />,
    link: "/LTC",
    
  },
  {
    name: "UNI",
   icon: <UNIIcon />,
    link: "/UNI",
  
  },
  {
    name: "LINK",
    icon: <LINKIcon />,
    link: "/LINK",
   
  },
  {
    name: "AAVE",
    icon: <AAVEICon />,
    link: "/AAVE",

  },
  {
    name: "COMP",
    icon: <COMPIcon />,
    link: "/COMP",
  },
  {
    name: "BAT",
    icon: <BATIcon />,
    link: "/BAT",
  },
  {
    name: "ENJ",
    icon: <ENJIcon />,
    link: "/ENJ",
  },
  {
    name: "GRT",  
   icon: <GRTIcon />,
    link: "/GRT",
  },
];
