import React from "react";
import "./homepage.css";
import Sidebar from "../components/sidebar/Sidebar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Itbconnection from "../components/intotheblock/itb";

const WebView = () => {
  return (
    <>
      <div className="home__hero-section darkBg">
        <div className="container">
          <div
            className="row home__hero-row"
           
          >
            <div className="col1">
              <div className="home__hero-text-wrapper">
                <Sidebar />
              </div>
            </div>
            <div className="col2">
              <div className="home__hero-text-wrapper">
                <Router>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={() => <Redirect to="/BTC" />}
                    />
                    <Route path="/:name" component={Itbconnection} />
                  </Switch>
                </Router>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebView;
