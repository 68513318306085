import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./NavBar.styles.scss";
import { NavDrawer } from "./NavDrawer";
import Logo from "../../assets/pdax_logo/PDAX-logo-invertedhires@2x.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#101624",
    },
  },
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpened: false,
    };
  }
  toggleDrawer = (booleanValue) => () => {
    this.setState({
      drawerOpened: booleanValue,
    });
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="navbar">
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className="menu-icon"
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <img src={Logo} className="header-logo" alt="logo" />
            </Toolbar>
          </AppBar>

          <NavDrawer
            drawerOpened={this.state.drawerOpened}
            toggleDrawer={this.toggleDrawer}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default NavBar;